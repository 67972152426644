import initialProfile from './profile';
import initialAgents from './agents';
import initialPostBack from './postback';
import initialDeploy from './deploy';
import initialMessages from './messages';
import initialAffiliates from './affiliates';
import initialAccount from './account';
import initialCommissionPlans from './commisionPlan';
import initialContentManagement from './contentManagement';

const neededReloadStructure = {
  addUser: false,
  transferToAgent: false,
};

const initialState = {
  account: initialAccount,
  forgotPasswordRequest: {
    isBusy: false,
    result: false,
  },
  deploy: initialDeploy,
  affiliatesList: [],
  messages: initialMessages,
  neededReload: { neededReloadStructure },
  profile: initialProfile,
  agents: initialAgents,
  affiliates: initialAffiliates,
  postBack: initialPostBack,
  commisionPlans: initialCommissionPlans,
  contentManagement: initialContentManagement,
};

export default initialState;
