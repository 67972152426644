import account from './account';
import messages from './messages';
import agentsActions from './agents';
import affiliatesActions from './affiliates';
import profile from './profile';
import postBack from './postback';
import * as deploy from './deploy';
import commissionPlans from './commission';
import contentManagement from './contentManagement';

/**
 * TODO
 * functions must be closed "Action" with word
 */
export default {
  ...account,
  ...messages,
  ...agentsActions,
  ...affiliatesActions,
  ...profile,
  ...postBack,
  ...deploy,
  ...commissionPlans,
  ...contentManagement,
};
