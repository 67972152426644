import COLORS from './colors';
import { PRODUCT_ICONS, SOCIAL_ICONS, WITHDRAWALS_ICON } from 'constants/defines/icons';
import { REGISTRATION_SUCCESS, RESET_PASSWORD } from 'configs/urls';

import lightMode from 'assets/icons/light-mode.svg';
import darkMode from 'assets/icons/dark-mode.svg';
import linkCreator from 'assets/media/icons/authorisation/link.png';
import socialShare from 'assets/media/icons/authorisation/share.png';
import mediaLogin from 'assets/media/icons/authorisation/media.png';
import xmlFeed from 'assets/media/icons/authorisation/xml.png';
import jsonFeed from 'assets/media/icons/authorisation/json.png';

import emailVerifySuccess from 'assets/media/landing-media/img/emailVerifySuccess.svg';
import registrationSuccess from 'assets/media/landing-media/img/registrationSuccess.svg';

export const IS_LOGGED_IN_VALUE = '1';
export const IS_LOGGED_IN_KEY = 'isLoggedIn';
export const IS_FILTERED_DATA_KEY = 'isFilteredData';
export const RESULT_STATUS_LOGOUT = 'ex';
export const ALL_DATA = -1;
export const LANGUAGE_COOKIE_KEY = 'languageCode';
export const TOKEN = 'token';
export const BACK_TOKEN = 'backtoken';
export const DEFAULT_PAGE = 'defaultPage';
export const CHANGE_ADMIN_PASSWORD_PAGE = 'changePartnerAdmin';
export const VERIFICATION_CODE = 2;
export const DATE = 'date';
export const SEARCH_FILTERS_COUNT = 10;
export const GET_PLAYERS_FOR_CURRENT_AGENT_LIMIT = 20;
export const GET_AGENTS_FOR_CURRENT_AGENT_LIMIT = 20;
export const OBJECT = 'object';
export const ENTER_KEY_CODE = 13;
export const DEFAULT_FONT_SIZE = '10px';
export const SITE_MODE_LIGHT = 'light';
export const SITE_MODE_DARK = 'dark';
export const DEFAULT_SITE_MODE = 'light';
export const TAG = 'tag';
export const NAVIGATE_COUNT = 1;
export const CURRENCIES_COUNT = 1;
export const BETWEEN = 'between';
export const EXPIRE_DATE = 'expireDate';
export const INFINITY_SYMBOL = '\u221e';
export const CELL_PHONE = 'cellPhone';
export const PARENT_AFFILIATE_ID = 'parentAffiliateId';
export const PHONE_NUMBER = 'phoneNumber';
export const PARENT_ID = 'parentId';
export const CALCULATE_NOW = 'calculateNow';
export const STRING_TYPE = 'string';
export const DROPDOWN_SEARCH_VISIBLE_MAX_COUNT = 5;
export const FILE_UPLOADER = 'fileUploader';

export const FONT_SIZES = [
  {
    title: 'small',
    key: '8px',
  },
  {
    title: 'normal',
    key: '10px',
  },
  {
    title: 'large',
    key: '12px',
  },
];

export const SITE_MODE = [
  {
    title: 'light',
    key: SITE_MODE_LIGHT,
    imgName: lightMode,
  },
  {
    title: 'dark',
    key: SITE_MODE_DARK,
    imgName: darkMode,
  },
];

export const TO_FIX_COUNT = 2;

export const LOGIN_TYPE = {
  AFFILIATE: 0,
  AGENT: 1,
};

export const USER_STATUS = {
  PASSIVE: 1,
  ACTIVE: 2,
  BLOCKED: 3,
  DELETED: 4,
};
export const COLOR_OP_HASH_TAG = '1c';

export const USERS_STATUS_LIST = {
  [USER_STATUS.PASSIVE]: {
    TITLE: 'passive',
    COLOR: COLORS.PASSIVE,
  },
  [USER_STATUS.ACTIVE]: {
    TITLE: 'active',
    COLOR: COLORS.ACTIVE,
  },
  [USER_STATUS.BLOCKED]: {
    TITLE: 'blocked',
    COLOR: COLORS.BLOCKED,
  },
  [USER_STATUS.DELETED]: {
    TITLE: 'deleted',
    COLOR: COLORS.DELETED,
  },
};

export const WITHDRAWALS_STATUS = {
  PENDING: 0,
  ACCEPTED: 1,
  DENIED: 2,
  WAITING: 3,
  REOPEN: 4,
};

export const WITHDRAWALS_STATUS_LIST = {
  [WITHDRAWALS_STATUS.PENDING]: {
    COLOR: COLORS.PENDING,
    IMG: WITHDRAWALS_ICON.PENDING,
    TITLE: 'pending',
  },
  [WITHDRAWALS_STATUS.ACCEPTED]: {
    COLOR: COLORS.ACCEPTED,
    IMG: WITHDRAWALS_ICON.ACCEPTED,
    TITLE: 'accepted',
  },
  [WITHDRAWALS_STATUS.DENIED]: {
    COLOR: COLORS.DENIED,
    IMG: WITHDRAWALS_ICON.DENIED,
    TITLE: 'denied',
  },
  [WITHDRAWALS_STATUS.WAITING]: {
    COLOR: COLORS.WAITING,
    IMG: WITHDRAWALS_ICON.WAITING,
    TITLE: 'waiting',
  },
  [WITHDRAWALS_STATUS.REOPEN]: {
    COLOR: COLORS.REOPEN,
    IMG: WITHDRAWALS_ICON.REOPEN,
    TITLE: 'reopen',
  },
};

export const BANNER_STATUS = {
  WAITING: {
    ID: 'WAITING',
    LABEL: 'pending',
  },
};

export const WITHDRAWALS_WIDGETS_DATA_PARAMS = {
  widgetsNamesLists: {
    widgets: ['getPendingWithdrawCount', 'getDeniedWithdrawCount', 'getAcceptedWithdrawCount'],
    charts: ['withdrawStatsByDay'],
  },
};

export const FILTERS_LIMIT_START = {
  start: 0,
  limit: 10,
};

export const DEFAULT_REPORTS_FILTERS_PARAMS = {
  ...FILTERS_LIMIT_START,
  filter: {},
};

export const DEFAULT_REPORTS_FILTERS_PARAMS_ALL_LIST = {
  start: 0,
  limit: ALL_DATA,
};

export const REPORTS_FILTERS_PARAMS_ALL_LIST = {
  ...DEFAULT_REPORTS_FILTERS_PARAMS,
  ...DEFAULT_REPORTS_FILTERS_PARAMS_ALL_LIST,
};

export const SKIN_TYPES = {
  AFFILIATE: 1,
  FEED: 3,
  AGENT: 4,
  AGENT_AFFILIATE: 5,
  PARTNER_API: 6,
  COMPANY_ADMIN: 7,
  DEPLOY_SKIN: 8,
};

export const USER_TYPES = {
  AFFILIATE: 0,
  AGENT: 1,
};

export const PERMISSION_STATUS = {
  ACCEPTED: 'YES',
  DECLINED: 'NO',
};

export const AFFILIATES_ROLE = {
  ADMIN: -1,
  PARTNER_ADMIN: 0,
  SUPER_AFFILIATE: 1,
  AFFILIATE: 2,
  RISK: 3,
  MARKETING: 4,
};

export const AGENT_ROLE = {
  PARTNER_ADMIN: 0,
  CONTINENTAL_MANAGER: 20,
  COUNTRY_MANAGER: 30,
  REGIONAL_MANAGER: 40,
  CITY_MANAGER: 50,
  AREA_MANAGER: 60,
  AGENT: 70,
  VIEW: 80,
};

export const LIST_PAYOUT_STATUS_COLOR = {
  VALID: '#27CA41',
  INVALID: '#FF6159',
};

export const AFFILIATE_ROLE_STATUS = {
  [AFFILIATES_ROLE.ADMIN]: {
    TITLE: 'admin',
    KEY: 'ADMIN',
    COLOR: '#3ec3ef',
  },
  [AFFILIATES_ROLE.PARTNER_ADMIN]: {
    TITLE: 'partner-admin',
    KEY: 'PARTNER_ADMIN',
    COLOR: '#47b4a9',
  },
  [AFFILIATES_ROLE.SUPER_AFFILIATE]: {
    TITLE: 'super-affiliate',
    KEY: 'SUPER_AFFILIATE',
    COLOR: '#ffca5f',
  },
  [AFFILIATES_ROLE.AFFILIATE]: {
    TITLE: 'affiliate',
    KEY: 'AFFILIATE',
    COLOR: '#7dc681',
  },
  [AFFILIATES_ROLE.RISK]: {
    TITLE: 'risk',
    KEY: 'RISK',
    COLOR: '#f7776e',
  },
  [AFFILIATES_ROLE.MARKETING]: {
    TITLE: 'marketing',
    KEY: 'MARKETING',
    COLOR: '#9171cb',
  },
};

export const AGENT_ROLE_STATUS = {
  [AGENT_ROLE.PARTNER_ADMIN]: {
    TITLE: 'partner-admin',
    KEY: 'partner-admin',
    COLOR: '#47b4a9',
  },
  [AGENT_ROLE.CONTINENTAL_MANAGER]: {
    TITLE: 'continental-manager',
    KEY: 'CONTINENTAL_MANAGER',
    COLOR: '#3ec3ef',
  },
  [AGENT_ROLE.COUNTRY_MANAGER]: {
    TITLE: 'country-manager',
    KEY: 'COUNTRY_MANAGER',
    COLOR: '#ffca5f',
  },
  [AGENT_ROLE.REGIONAL_MANAGER]: {
    TITLE: 'regional-manager',
    KEY: 'REGIONAL_MANAGER',
    COLOR: '#f7776e',
  },
  [AGENT_ROLE.CITY_MANAGER]: {
    TITLE: 'city-manager',
    KEY: 'CITY_MANAGER',
    COLOR: '#abbb36',
  },
  [AGENT_ROLE.AREA_MANAGER]: {
    TITLE: 'area-manager',
    KEY: 'AREA_MANAGER',
    COLOR: '#9171cb',
  },
  [AGENT_ROLE.AGENT]: {
    TITLE: 'agent',
    KEY: 'AGENT',
    COLOR: '#7dc681',
  },
  [AGENT_ROLE.VIEW]: {
    TITLE: 'view',
    KEY: 'VIEW',
    COLOR: '#47cfe0',
  },
};

export const ROLE_STATUS = {
  ...AFFILIATE_ROLE_STATUS,
  ...AGENT_ROLE_STATUS,
};

export const FORM_FIELD_TYPES = [
  'text',
  'radio',
  'number',
  'switcher',
  'dropdown',
  'checkbox',
  'textarea',
  'email',
  'password',
  'datepicker',
  'fileUploader',
];

export const FIRST_RISK_USERNAME = 'affiliates@risk.com';

export const COMMISSION_TYPE = {
  CPA: 1,
  HYBRID: 3,
  REFERRAL: 2,
  REVENUE_SHARE: 0,
};

export const COMMISSION_PlAN_NET_DEPOSIT = 3;
export const COMMISSION_PLAN_PLAYER_COUNT = 1;
export const COMMISSION_PLAN_REVENUE_SHARE = 2;
export const COMMISSION_PLAN_TURNOVER = 4;

export const REVENUE_SHARE_TYPES = {
  [COMMISSION_PLAN_PLAYER_COUNT]: {
    ICON: '',
    TITLE: 'player-count',
    ID: COMMISSION_PLAN_PLAYER_COUNT,
    COLOR: COLORS.COMMISSION_PLAN_PLAYER_COUNT_COLOR,
  },
  [COMMISSION_PLAN_REVENUE_SHARE]: {
    ICON: '',
    TITLE: 'rev-share',
    ID: COMMISSION_PLAN_REVENUE_SHARE,
    COLOR: COLORS.COMMISSION_PLAN_REVENUE_SHARE_COLOR,
  },
  [COMMISSION_PLAN_TURNOVER]: {
    ICON: '',
    TITLE: 'turnover',
    ID: COMMISSION_PLAN_TURNOVER,
    COLOR: COLORS.COMMISSION_PLAN_REVENUE_SHARE_COLOR,
  },
  [COMMISSION_PlAN_NET_DEPOSIT]: {
    ICON: '',
    TITLE: 'net-deposit',
    ID: COMMISSION_PlAN_NET_DEPOSIT,
    COLOR: COLORS.COMMISSION_PlAN_NET_DEPOSIT_COLOR,
  },
};

export const POST_BACK_TYPE_REGISTRATION = 1;
export const POST_BACK_TYPE_DEPOSIT = 2;
export const POST_BACK_TYPE_CPA = 3;

export const POST_BACK_TYPES = {
  [POST_BACK_TYPE_REGISTRATION]: {
    eventId: POST_BACK_TYPE_REGISTRATION,
    key: 'registration',
    title: 'registration',
    textareaLabel: 'registration-postback-url',
    copyButtonTitle: 'copy-registration-url',
  },
  [POST_BACK_TYPE_DEPOSIT]: {
    eventId: POST_BACK_TYPE_DEPOSIT,
    key: 'deposit',
    title: 'deposit',
    textareaLabel: 'deposit-postback-url',
    copyButtonTitle: 'copy-deposit-url',
  },
  [POST_BACK_TYPE_CPA]: {
    eventId: POST_BACK_TYPE_CPA,
    key: 'cpa',
    title: 'CPA',
    textareaLabel: 'cpa-postback-url',
    copyButtonTitle: 'copy-cpa-url',
  },
};

export const COMMISSION_TYPE_STATUS = {
  [COMMISSION_TYPE.REVENUE_SHARE]: {
    TITLE: 'revenue',
    ID: COMMISSION_TYPE.REVENUE_SHARE,
    COLOR: COLORS.COMMISSION_TYPE_REVENUE_SHARE,
    ICON: 'bc-icon-commission-plan-48',
  },
  [COMMISSION_TYPE.CPA]: {
    TITLE: 'cpa',
    ID: COMMISSION_TYPE.CPA,
    COLOR: COLORS.COMMISSION_TYPE_CPA,
    ICON: 'bc-icon-commission-plan-48',
  },
  [COMMISSION_TYPE.REFERRAL]: {
    TITLE: 'referral',
    ID: COMMISSION_TYPE.REFERRAL,
    COLOR: COLORS.COMMISSION_TYPE_REFERRAL,
    ICON: 'bc-icon-commission-plan-48',
  },
  [COMMISSION_TYPE.HYBRID]: {
    TITLE: 'hybrid',
    ID: COMMISSION_TYPE.HYBRID,
    COLOR: COLORS.COMMISSION_TYPE_HYBRID,
    ICON: 'bc-icon-commission-plan-48',
  },
};

export const REVENUE_SHARE_TYPES_RANGE = {
  [COMMISSION_PLAN_REVENUE_SHARE]: {
    FROM: 'from-ngr',
    TO: 'to-ngr',
    PERCENT: 'ngr',
  },
  [COMMISSION_PLAN_TURNOVER]: {
    FROM: 'bet-amount-from',
    TO: 'bet-amount-to',
    PERCENT: 'turnover',
  },
  [COMMISSION_PLAN_PLAYER_COUNT]: {
    FROM: 'from',
    TO: 'to',
    PERCENT: 'ngr',
  },
  [COMMISSION_PlAN_NET_DEPOSIT]: {
    FROM: 'from',
    TO: 'to',
    PERCENT: 'ndwa',
  },
};

export const CONDITIONS_ACTIONS = [
  {
    label: '=',
    value: '=',
  },
  {
    label: '<',
    value: '<',
  },
  {
    label: '<=',
    value: '<=',
  },
  {
    label: '>',
    value: '>',
  },
  {
    label: '>=',
    value: '>=',
  },
];

export const FILTER_ACTIONS = {
  EQ: '=',
  NEQ: '!=',
  LTE: '<=',
  GTE: '>=',
  LT: '<',
  GT: '>',
  IN: 'in',
  LIKE: 'like',
  BETWEEN: 'between',
  MULTISELECT_LAZY_LOADING: 'multiselectLazyLoading',
  MONTH_PICKER: 'monthPicker',
};

export const FILTER_ACTIONS_LIST = [
  FILTER_ACTIONS.EQ,
  FILTER_ACTIONS.LTE,
  FILTER_ACTIONS.GTE,
  FILTER_ACTIONS.LT,
  FILTER_ACTIONS.GT,
  FILTER_ACTIONS.IN,
  FILTER_ACTIONS.LIKE,
  FILTER_ACTIONS.BETWEEN,
  FILTER_ACTIONS.NEQ,
];

export const ONLY_ACTIVE_PLAYERS = {
  ALL_PLAYERS: 0,
  ACTIVE_PLAYERS: 1,
};

export const FILTER_STATUS = {
  [FILTER_ACTIONS.EQ]: '=',
  [FILTER_ACTIONS.LIKE]: 'contains',
  [FILTER_ACTIONS.IN]: 'in',
  [FILTER_ACTIONS.BETWEEN]: 'between',
  [FILTER_ACTIONS.LTE]: '<=',
  [FILTER_ACTIONS.GTE]: '>=',
  [FILTER_ACTIONS.LT]: '<',
  [FILTER_ACTIONS.GT]: '>',
  [FILTER_ACTIONS.NEQ]: '≠',
};

export const TABLE_LIMITS = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
  {
    label: '1000',
    value: 1000,
  },
];

export const PAGINATION_SELECTOR_DATA = [
  ...TABLE_LIMITS,
  {
    label: 'All',
    value: ALL_DATA,
  },
];

export const COSTS_TYPES = {
  WITHDRAW: 0,
  DEPOSIT: 1,
};

export const COSTS_STATUS = {
  [COSTS_TYPES.WITHDRAW]: 'withdraw',
  [COSTS_TYPES.DEPOSIT]: 'deposit',
};

export const MAX_GRID_COL_COUNT = 12;

export const RESPONSE_SUCCESS_INFO = {
  title: 'Success',
  message: 'saved',
  iconProps: {
    isFilled: true,
  },
};
export const RESPONSE_SUCCESS_DELETE_INFO = {
  title: 'success',
  message: 'successfully-deleted',
  iconProps: {
    isFilled: true,
  },
};

export const RESPONSE_SUCCESS_NEWS_LETTER = {
  send: {
    title: 'success',
    message: 'newsletter-was-sent',
    iconProps: {
      isFilled: true,
    },
  },
  process: {
    title: 'info',
    message: 'your-request-is-in-process',
    iconProps: {
      isFilled: true,
    },
  },
};

export const MESSAGE_RESPONSE = {
  SUCCESS: {
    title: 'success',
    message: 'send',
    iconProps: {
      isFilled: true,
    },
  },
  ERROR: {
    title: 'error',
    message: 'error',
    iconProps: {
      isFilled: true,
    },
  },
};

export const SUCCESS_SENT = {
  title: 'success',
  message: 'sent',
  iconProps: {
    isFilled: true,
  },
};

export const SUCCESSFUL_CHANGES_INFO = {
  title: 'Success',
  message: 'changes-have-been-successfully-done',
  iconProps: {
    isFilled: true,
  },
};

export const UNABLE_SAVE_EMPTY_EMAIL = {
  title: 'error',
  message: 'unable-to-save-empty-text',
  iconProps: {
    isFilled: true,
  },
};

export const PRODUCT_KEYS = {
  SPORTS_BOOK: 'sportsbook',
  BET_ON_POLITICS: 'betOnPolitics',
  LIVE_GAMES: 'liveGames',
  VIRTUAL_GAMES: 'virtualGames',
  SKILL_GAMES: 'skillGames',
  BETTING_GAMES: 'bettingGames',
  POOL_BETTING_GAMES: 'poolBettingGames',
  SLOTS: 'slots',
  THIRD_PARTY_POKER: 'poker',
  TABLE_GAMES: 'tableGames',
  VIDEO_POKER: 'videoPoker',
  BET_CONSTRUCT_POKER: 'nativePoker',
  FANTASY_SPORT: 'fantasySport',
};

export const PRODUCT_ID_BY_KEYS = {
  [PRODUCT_KEYS.SPORTS_BOOK]: 1,
  [PRODUCT_KEYS.BET_ON_POLITICS]: 3,
  [PRODUCT_KEYS.LIVE_GAMES]: 11,
  [PRODUCT_KEYS.VIRTUAL_GAMES]: 12,
  [PRODUCT_KEYS.SKILL_GAMES]: 13,
  [PRODUCT_KEYS.BETTING_GAMES]: 14,
  [PRODUCT_KEYS.POOL_BETTING_GAMES]: 15,
  [PRODUCT_KEYS.SLOTS]: 16,
  [PRODUCT_KEYS.THIRD_PARTY_POKER]: 17,
  [PRODUCT_KEYS.TABLE_GAMES]: 18,
  [PRODUCT_KEYS.VIDEO_POKER]: 19,
  [PRODUCT_KEYS.BET_CONSTRUCT_POKER]: 20,
  [PRODUCT_KEYS.FANTASY_SPORT]: 21,
};

export const PRODUCT_LIST = {
  SPORTS_BOOK: 1,
  BET_ON_POLITICS: 3,
  FIRST_DEPOSITING: 4,
  LIVE_GAMES: 11,
  VIRTUAL_GAMES: 12,
  SKILL_GAMES: 13,
  BETTING_GAMES: 14,
  POOL_BETTING_GAMES: 15,
  SLOTS: 16,
  THIRD_PARTY_POKER: 17,
  TABLE_GAMES: 18,
  VIDEO_POKER: 19,
  BET_CONSTRUCT_POKER: 20,
  FANTASY_SPORT: 21,
};

export const PRODUCT_STATUS = {
  [PRODUCT_LIST.SPORTS_BOOK]: {
    ICON: PRODUCT_ICONS.SPORTS_BOOK,
    LABEL: 'sportsbook',
    COLOR: COLORS.SPORTSBOOK,
  },
  [PRODUCT_LIST.BET_ON_POLITICS]: {
    ICON: PRODUCT_ICONS.BET_ON_POLITICS,
    LABEL: 'betonpolitics',
    COLOR: COLORS.BET_ON_POLITICS,
  },
  [PRODUCT_LIST.LIVE_GAMES]: {
    ICON: PRODUCT_ICONS.LIVE_GAMES,
    LABEL: 'live-games',
    COLOR: COLORS.LIVE_GAMES,
  },
  [PRODUCT_LIST.VIRTUAL_GAMES]: {
    ICON: PRODUCT_ICONS.VIRTUAL_GAMES,
    LABEL: 'virtual-games',
    COLOR: COLORS.VIRTUAL_GAMES,
  },
  [PRODUCT_LIST.SKILL_GAMES]: {
    ICON: PRODUCT_ICONS.SKILL_GAMES,
    LABEL: 'skill-games',
    COLOR: COLORS.SKILL_GAMES,
  },
  [PRODUCT_LIST.BETTING_GAMES]: {
    ICON: PRODUCT_ICONS.BETTING_GAMES,
    LABEL: 'betting-games',
    COLOR: COLORS.BETTING_GAMES,
  },
  [PRODUCT_LIST.POOL_BETTING_GAMES]: {
    ICON: PRODUCT_ICONS.POOL_BETTING_GAMES,
    LABEL: 'pool-betting-games',
    COLOR: COLORS.POOL_BETTING_GAMES,
  },
  [PRODUCT_LIST.SLOTS]: {
    ICON: PRODUCT_ICONS.SLOTS,
    LABEL: 'slots',
    COLOR: COLORS.SLOTS,
  },
  [PRODUCT_LIST.THIRD_PARTY_POKER]: {
    ICON: PRODUCT_ICONS.THIRD_PARTY_POKER,
    LABEL: 'third-party-poker',
    COLOR: COLORS.THIRD_PARTY_POKER,
  },
  [PRODUCT_LIST.TABLE_GAMES]: {
    ICON: PRODUCT_ICONS.TABLE_GAMES,
    LABEL: 'table-games',
    COLOR: COLORS.TABLE_GAMES,
  },
  [PRODUCT_LIST.VIDEO_POKER]: {
    ICON: PRODUCT_ICONS.VIDEO_POKER,
    LABEL: 'video-poker',
    COLOR: COLORS.VIDEO_POKER,
  },
  [PRODUCT_LIST.BET_CONSTRUCT_POKER]: {
    ICON: PRODUCT_ICONS.BET_CONSTRUCT_POKER,
    LABEL: 'betconstruct-poker',
    COLOR: COLORS.BET_CONSTRUCT_POKER,
  },
  [PRODUCT_LIST.FANTASY_SPORT]: {
    ICON: PRODUCT_ICONS.FANTASY_SPORT,
    LABEL: 'fantasy-sport',
    COLOR: COLORS.FANTASY_SPORT,
  },
};

export const PRODUCT_GROUPS = {
  SPORTS_BOOK_GROUP: 'Sportsbook',
  CASINO_GROUP: 'Casino',
  SKILL_GAMES_GROUP: 'Skill Games',
  BET_ON_POLITICS_GROUP: 'Betonpolitics',
};

export const HEADER_CONTENT = {
  FORM_DATA: {
    headers: { 'content-type': 'multipart/form-data' },
  },
};

export const CARRY_OVER = {
  NEGATIVE: 0,
  NO_NEGATIVE: 1,
};

export const CARRY_OVER_LIST = {
  [CARRY_OVER.NEGATIVE]: {
    LABEL: 'negative',
  },
  [CARRY_OVER.NO_NEGATIVE]: {
    LABEL: 'no-negative',
  },
};

export const AVAILABLE_TEMPLATES = t => [
  {
    key: 'mail_tmp_registration',
    label: t('registration'),
  },
  {
    key: 'mail_tmp_password_reset',
    label: t('Password-Reset'),
  },
  {
    key: 'mail_tmp_password_successful_reset',
    label: t('Password-Successful-Reset'),
  },
  {
    key: 'mail_tmp_password_changing',
    label: t('Password-Changing'),
  },
  {
    key: 'mail_tmp_blocking_affiliate',
    label: t('Blocking-Affiliate'),
  },
  {
    key: 'mail_tmp_activating_affiliate',
    label: t('Activating-Affiliate'),
  },
  {
    key: 'mail_tmp_successful_withdraw',
    label: t('Successful-Withdraw'),
  },
  {
    key: 'mail_tmp_unsuccessful_withdraw',
    label: t('Unsuccessful-Withdraw'),
  },
];

export const AGENT_AVAILABLE_TEMPLATES = t => [
  {
    key: 'mail_tmp_registration_agent',
    label: t('registration'),
  },
];

export const SMS_AVAILABLE_TEMPLATES = [
  {
    key: 'sms_tmp_password_reset',
    label: 'reset-password',
  },
];

export const MONTHLY_CHARTS = {
  affiliateSignups: {
    title: 'affiliates-signups',
    color: COLORS.AFFILIATE_SIGNUPS,
  },
  playersSignups: {
    title: 'players-signups',
    color: COLORS.PLAYERS_SIGNUPS,
  },
  deposits: {
    title: 'deposits',
    color: COLORS.DEPOSIT,
  },
  win: {
    title: 'win',
    color: COLORS.WINS,
  },
  bet: {
    title: 'bet',
    color: COLORS.BETS,
  },
  grossRevenue: {
    title: 'gross-revenue',
    color: COLORS.GROSS_REVENUE,
  },
  expences: {
    title: 'expenses',
    color: COLORS.EXPENCES,
  },
  bonuses: {
    title: 'bonuses',
    color: COLORS.BONUS,
  },
  convertedBonuses: {
    title: 'converted-bonuses',
    color: COLORS.CONVERTED_BONUSES,
  },
  netRevenue: {
    title: 'net-revenue',
    color: COLORS.NET_REVENUE,
  },
  cpa: {
    title: 'cpa',
    color: COLORS.COMMISSION_TYPE_CPA,
  },
  commissions: {
    title: 'commissions',
    color: COLORS.COMMISSIONS,
  },
};

export const LINKS_COLOR = ['rgba(171, 212, 124)', 'rgba(255, 173, 95)', 'rgba(239, 93, 143)'];

export const ACTIVITY_KEYS = [
  'signUps',
  'firstActiveUsers',
  'activeUsers',
  'firstDepositing',
  'deposit',
  'depositSum',
  'impressions',
  'clicks',
  'cr',
  'ngrSum',
  'commissionSum',
];

export const SOCIAL_SHARE = {
  ACTIVE: 1,
  PASSIVE: 0,
};

export const SOCIAL_LIST = {
  FACEBOOK: 'facebook',
  X: 'x',
  VKONTAKTE: 'vkontakte',
  INSTAGRAM: 'instagram',
  YOUTUBE: 'youtube',
  ODNOKLASSNIKI: 'odnoklassniki',
  LINKEDIN: 'linkedin',
  VIMEO: 'vimeo',
  TELEGRAM: 'telegram',
  TIKTOK: 'tiktok',
  PINTEREST: 'pinterest',
};

export const SOCIAL = {
  [SOCIAL_LIST.FACEBOOK]: {
    ICON: SOCIAL_ICONS.FACEBOOK,
    LABEL: 'facebook',
  },
  [SOCIAL_LIST.X]: {
    ICON: SOCIAL_ICONS.X,
    LABEL: 'X',
  },
  [SOCIAL_LIST.VKONTAKTE]: {
    ICON: SOCIAL_ICONS.VKONTAKTE,
    LABEL: 'vkontakte',
  },
  [SOCIAL_LIST.INSTAGRAM]: {
    ICON: SOCIAL_ICONS.INSTAGRAM,
    LABEL: 'instagram',
  },
  [SOCIAL_LIST.YOUTUBE]: {
    ICON: SOCIAL_ICONS.YOUTUBE,
    LABEL: 'youtube',
  },
  [SOCIAL_LIST.ODNOKLASSNIKI]: {
    ICON: SOCIAL_ICONS.ODNOKLASSNIKI,
    LABEL: 'odnoklassniki',
  },
  [SOCIAL_LIST.LINKEDIN]: {
    ICON: SOCIAL_ICONS.LINKEDIN,
    LABEL: 'linkedin',
  },
  [SOCIAL_LIST.VIMEO]: {
    ICON: SOCIAL_ICONS.VIMEO,
    LABEL: 'vimeo',
  },
  [SOCIAL_LIST.TELEGRAM]: {
    ICON: SOCIAL_ICONS.TELEGRAM,
    LABEL: 'telegram',
  },
  [SOCIAL_LIST.TIKTOK]: {
    ICON: SOCIAL_ICONS.TIKTOK,
    LABEL: 'TikTok',
  },
  [SOCIAL_LIST.PINTEREST]: {
    ICON: SOCIAL_ICONS.PINTEREST,
    LABEL: 'Pinterest',
  },
};

export const LOG_OUT_PAGE_CALLS = {
  CPA: 'commission_plan_cpa',
  REVENUE: 'commission_plan_revenue',
  SUB_AFFILIATES: 'commission_plan_sub_affiliates',
  HYBRID: 'commission_plan_hybrid',
  TERMS_AND_CONDITIONS: 'terms_and_conditions',
  FAQ: 'faq',
  ABOUT_US: 'about_us',
  SLOGAN: 'slogan',
};

export const UP_COMING_GAMES_LIST = [
  {
    key: 1,
    label: '1 hours',
  },
  {
    key: 2,
    label: '2 hours',
  },
  {
    key: 3,
    label: '3 hours',
  },
  {
    key: 6,
    label: '6 hours',
  },
  {
    key: 12,
    label: '12 hours',
  },
  {
    key: 24,
    label: '24 hours',
  },
  {
    key: 48,
    label: '48 hours',
  },
];

export const FEED_TYPE = [
  {
    id: 'preMatch',
    label: 'preMatch',
  },
  {
    id: 'live',
    label: 'live',
  },
];

export const FEED_DEFAULT_TIMEZONE = 'UTC';

export const ACTION_TYPE_ADD = 'add';
export const ACTION_TYPE_EDIT = 'edit';
export const ACTION_TYPE_DELETE = 'delete';

export const IS_REGISTER = {
  agent: 1,
  affiliate: 2,
  registration: 3,
  player: 4,
};

export const TRANSACTION_AGENT_TYPE = {
  bonus: 7,
  deposit: 2,
  withdraw: 3,
  wallet_correction: 6,
  monthly_calculation: 1,
};

export const TRANSACTION_AGENT_TYPE_LIST = {
  [TRANSACTION_AGENT_TYPE.bonus]: {
    label: 'bonus',
  },
  [TRANSACTION_AGENT_TYPE.deposit]: {
    label: 'deposit',
  },
  [TRANSACTION_AGENT_TYPE.withdraw]: {
    label: 'withdraw',
  },
};

export const TRANSACTION_AGENT_ACTION_TYPE = {
  REAL: 3,
  CREDIT: 2,
};

export const TRANSACTION_AGENT_ACTION_TYPE_LIST = {
  [TRANSACTION_AGENT_ACTION_TYPE.REAL]: {
    label: 'real',
  },
  [TRANSACTION_AGENT_ACTION_TYPE.CREDIT]: {
    label: 'credit',
  },
};

export const MAIL_TEMPLATES_DYNAMIC_VARIABLES = [
  {
    label: 'partner-name',
    variable: '{name}',
  },
  {
    label: 'partner-site-url-name',
    variable: '{url}',
  },
  {
    label: 'partner-affiliate-site-url',
    variable: '{affiliateUrl}',
  },
  {
    label: 'token-url',
    variable: '{tokenLink}',
  },
  {
    label: 'user-full-name',
    variable: '{userFullName}',
  },
  {
    label: 'status',
    variable: '{status}',
  },
];

export const POPOVER_DEFAULT_PROPS = {
  align: 'end',
  maxHeight: 195,
  extendTargetWidth: false,
};

export const MESSAGES_STATUS = {
  ALL: 'ALL',
  READ: 'READ',
  UNREAD: 'UNREAD',
};

export const MESSAGES_TABS = {
  CHATS: 'chats',
  SCHEDULED: 'scheduled',
};

export const NETWORK_USER_MULTIPLE_ACTION = {
  MOVE_TO_GROUP: 1,
  SET_BET_LIMIT: 2,
  SET_COMMISSION_PLAN: 3,
  CHANGE_STATUS: 4,
};

export const getNetworkMultipleOption = (t, isPermitted, moveToGroup) => {
  const { MOVE_TO_GROUP, SET_BET_LIMIT, SET_COMMISSION_PLAN, CHANGE_STATUS } = NETWORK_USER_MULTIPLE_ACTION;

  return [
    {
      label: t('move-to-group'),
      value: MOVE_TO_GROUP,
      permission: moveToGroup,
    },
    {
      label: t('set-bet-limit'),
      value: SET_BET_LIMIT,
      permission: isPermitted('agentBetLimit'),
    },
    {
      label: t('set-commission-plan'),
      value: SET_COMMISSION_PLAN,
      permission: isPermitted('changeAffiliateCommissionPlan'),
    },
    {
      label: t('change-status'),
      value: CHANGE_STATUS,
      permission: isPermitted('changeAffiliateStatus'),
    },
  ];
};

export const DYNAMIC_VARIABLES_NEWSLETTER = 2;
export const DYNAMIC_VARIABLES_MAIL_TEMPLATES = 1;

export const NEWS_LETTER_LAYOUTS_TYPE_SELECTED = 2;
export const NEWS_LETTER_LAYOUTS_TYPE_LIST = [
  {
    value: 1,
    label: 'Type1',
  },
  {
    value: 2,
    label: 'Type2',
  },
  {
    value: 3,
    label: 'Type3',
  },
];

export const TOKEN_REGISTER = 'REGISTER';
export const TOKEN_PASSWORD_RESET = 'PASSWORD_RESET';

export const TOKEN_PAGES = {
  [TOKEN_PASSWORD_RESET]: RESET_PASSWORD,
  [TOKEN_REGISTER]: REGISTRATION_SUCCESS,
};

export const TIMER_FINISHED_TYPING = 600;
export const TIMER_FINISHED_TYPING_2500 = 2500;

export const USER_MAIN_CURRENCY = 'mainCurrency';
export const DEFAULT_CURRENCY = 'defaultCurrency';
export const USER_REPORT_CURRENCY = 'reportCurrency';

export const DROPDOWN_VALUE_KEYS = {
  mainCurrency: 'iso',
  gender: 'id',
  countryCode: 'code',
  agentRole: 'id',
  parentAffiliateId: 'affiliateId',
  preferredBrands: 'id',
};

export const DROPDOWN_LABEL_KEYS = {
  mainCurrency: 'iso',
  gender: 'label',
  countryCode: 'label',
  agentRole: 'label',
  parentAffiliateId: 'username',
};

export const GENDER_LIST = t => [
  { id: 'male', label: t('male') },
  { id: 'female', label: t('female') },
  { id: 'other', label: t('other') },
];

export const SELECTED = 'selected';

export const BET_STATES = {
  NEW: 'New',
  WON: 'Won',
  LOST: 'Lost',
  CASHOUT: 'CashedOut',
  ACCEPTED: 'Accepted',
  RETURNED: 'Returned',
  REJECTED: 'Rejected',
  NOT_RESULTED: 'NotResulted',
};

export const BET_STATES_TYPES = {
  [BET_STATES.NEW]: {
    ICON: '',
    TITLE: 'new',
    COLOR: COLORS.BET_STATES_NEW,
  },
  [BET_STATES.WON]: {
    ICON: '',
    TITLE: 'won',
    COLOR: COLORS.BET_STATES_WON,
  },
  [BET_STATES.LOST]: {
    ICON: '',
    TITLE: 'lost',
    COLOR: COLORS.BET_STATES_LOST,
  },
  [BET_STATES.CASHOUT]: {
    ICON: '',
    TITLE: 'cashout',
    COLOR: COLORS.BET_STATES_CASH_OUT,
  },
  [BET_STATES.ACCEPTED]: {
    ICON: '',
    TITLE: 'accepted',
    COLOR: COLORS.BET_STATES_ACCEPTED,
  },
  [BET_STATES.RETURNED]: {
    ICON: '',
    TITLE: 'returned',
    COLOR: COLORS.BET_STATES_RETURNED,
  },
  [BET_STATES.REJECTED]: {
    ICON: '',
    TITLE: 'rejected',
    COLOR: COLORS.BET_STATES_REJECTED,
  },
  [BET_STATES.NOT_RESULTED]: {
    ICON: '',
    TITLE: 'not-resulted',
    COLOR: COLORS.BET_STATES_NOT_RESULTED,
  },
};

export const USER_TYPE_DELETE = 'delete';
export const USER_TYPE_MOVE = 'moveParent';

export const DELETE_TYPES = {
  MOVE: USER_TYPE_MOVE,
  DELETE: USER_TYPE_DELETE,
};

export const CHANGE_PASSWORD_TYPES = {
  AGENT: 'agent',
  PLAYER: 'player',
  AFFILIATE: 'affiliate',
};

export const NOTIFICATION_STATUS = {
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
};

export const BET_TYPE_SINGLE = 1;
export const BET_TYPE_MULTIPLE = 2;
export const BET_TYPE_SYSTEM = 3;
export const BET_TYPE_CHAIN = 'chain';

export const BET_TYPES = {
  [BET_TYPE_SINGLE]: {
    LABEL: 'single',
  },
  [BET_TYPE_MULTIPLE]: {
    LABEL: 'multiple',
  },
  [BET_TYPE_SYSTEM]: {
    LABEL: 'system',
  },
};

export const TRANSFER_FILTERS_CONSTANT = {
  NOK: 'NOK',
  WAITING: 'waiting',
  TRANSFER_STATUS: 'transferStatus',
};

export const MEDIA_BANNER_STATUS = {
  NOK_DEL: 'NOK_DEL',
  WAITING: 'WAITING',
  NOK: 'NOK',
  OK: 'OK',
};

export const MEDIA_BANNER_STATUS_LIST = {
  [MEDIA_BANNER_STATUS.NOK_DEL]: {
    COLOR: COLORS.DENIED,
  },
  [MEDIA_BANNER_STATUS.WAITING]: {
    COLOR: COLORS.PENDING,
  },
  [MEDIA_BANNER_STATUS.NOK]: {
    COLOR: COLORS.BLOCKED,
  },
  [MEDIA_BANNER_STATUS.OK]: {
    COLOR: COLORS.ACCEPTED,
  },
};

export const BANNER_TYPE = {
  HTML5: 3,
};

export const TABLE_BREADCRUMB_SLUG = {
  FIRST_LEVEL: 'firstLevel',
};

export const BRAND_IMAGE_TYPE = {
  LOGO: 'logo',
  LOGIN_LOGO: 'loginLogo',
  MOBILE_BACKGROUND: 'backgroundMobileImage',
  BACKGROUND: 'backgroundImage',
  LOGIN_BACKGROUND: 'loginBackgroundImage',
  FAVICON: 'favicon',
};

export const LOGO_SIZE = {
  WIDTH: 135,
  HEIGHT: 64,
};

export const ALL = 'all',
  EXCLUDED = 'excluded';
export const FILTERED_DATA_TYPES = [ALL, EXCLUDED];

export const FILTERED_DATA_HOVER = {
  [ALL]: {
    title: 'all-players-activity',
    value: 0,
  },
  [EXCLUDED]: {
    title: 'exclude-cpa-earned-players',
    value: 1,
  },
};

export const NETWORK_TYPES = [
  {
    id: 'all',
    value: 'all',
    label: 'whole-network',
  },
  {
    id: 'direct',
    value: 'direct',
    label: 'direct-players',
  },
];

export const MULTI_CURRENCY = {
  id: 'multi',
  iso: 'multi',
  name: 'multi',
  symbol: 'multi',
};

export const DEPLOY_SKIN_PARTNER_STATUS = [
  {
    value: 'active',
    label: 'active',
  },
  {
    value: 'passive',
    label: 'passive',
  },
];

export const PARTNER_STATUS = {
  ACTIVE: 'Active',
  PASSIVE: 'Passive',
};

export const LINKS_STATUS = {
  ACTIVE: 2,
  PASSIVE: 1,
};

export const DEPLOY_LOG_TYPE = {
  GLOBAL: 'global',
  SKIN: 'skin',
};

export const MEDIA_STATISTICS_CHART_KEY = {
  ACTIVE_MEDIA: 'activeMedia',
  SUM_CLICK: 'sumClick',
  SUM_SIGN_UPS: 'sumSignUps',
  SUM_UNIQUE: 'sumUnique',
  SUM_VIEW: 'sumView',
};

export const MEDIA_STATISTICS_CHART_NAME = {
  [MEDIA_STATISTICS_CHART_KEY.ACTIVE_MEDIA]: 'active-media',
  [MEDIA_STATISTICS_CHART_KEY.SUM_CLICK]: 'clicks',
  [MEDIA_STATISTICS_CHART_KEY.SUM_SIGN_UPS]: 'impressions',
  [MEDIA_STATISTICS_CHART_KEY.SUM_UNIQUE]: 'unique-visitors',
  [MEDIA_STATISTICS_CHART_KEY.SUM_VIEW]: 'sign-ups',
};

export const LANDING_PAGE_LINKS = [
  {
    icon: linkCreator,
    text: 'link-creator',
  },
  {
    icon: socialShare,
    text: 'social-share',
  },
  {
    icon: mediaLogin,
    text: 'media',
  },
  {
    icon: xmlFeed,
    text: 'XML-feed',
  },
  {
    icon: jsonFeed,
    text: 'JSON-feed',
  },
];

export const SORT_TYPE = {
  DESC: 'desc',
  ASC: 'asc',
};

export const RATE_SOURCE_DEFAULT = 0,
  RATE_SOURCE_INDIVIDUAL = 1;

export const RATE_SOURCE = {
  [RATE_SOURCE_DEFAULT]: 'default',
  [RATE_SOURCE_INDIVIDUAL]: 'individual',
};

export const MOBILE_APPEARANCE = {
  POPOVER: 'popover',
  OVERSPREAD: 'overspread',
};

export const PAYMENT_SYSTEM_TYPE = {
  CUSTOM_MANUAL: 0,
  MANUAL: 1,
  AUTOMATIC: 2,
};

export const PAYMENT_SYSTEM_TYPE_LIST = {
  [PAYMENT_SYSTEM_TYPE.CUSTOM_MANUAL]: {
    LABEL: 'custom_manual',
    COLOR: COLORS.CUSTOM_MANUAL,
  },
  [PAYMENT_SYSTEM_TYPE.MANUAL]: {
    LABEL: 'manual',
    COLOR: COLORS.MANUAL,
  },
  [PAYMENT_SYSTEM_TYPE.AUTOMATIC]: {
    LABEL: 'automatic',
    COLOR: COLORS.AUTOMATIC,
  },
};

export const FORWARD_PASSWORD_TYPE = {
  EMAIL: 0,
  PHONE_NUMBER: 1,
};

export const RESTORE_PASSWORD_OPTION = {
  EMAIL: 1,
  PHONE_NUMBER: 2,
};

export const restorePasswordFieldOptions = t => [
  { label: t('with-e-mail-address'), value: RESTORE_PASSWORD_OPTION.EMAIL },
  {
    label: t('with-phone-number'),
    value: RESTORE_PASSWORD_OPTION.PHONE_NUMBER,
  },
];

export const COMMISSION_PLAN_LAYOUT_ELEMENT = {
  CPA: 2,
  REVENUE: 3,
  SUB_AFFILIATES: 4,
  HYBRID: 5,
};

export const COMMISSION_PLAN_LAYOUT_ELEMENT_LIST = {
  [COMMISSION_PLAN_LAYOUT_ELEMENT.CPA]: {
    title: 'cpa',
    icon: 'icon-cpa',
  },
  [COMMISSION_PLAN_LAYOUT_ELEMENT.REVENUE]: {
    title: 'revenue',
    icon: 'icon-cpa',
  },
  [COMMISSION_PLAN_LAYOUT_ELEMENT.SUB_AFFILIATES]: {
    title: 'sub-affiliates',
    icon: 'icon-affiliates',
  },
  [COMMISSION_PLAN_LAYOUT_ELEMENT.HYBRID]: {
    title: 'hybrid',
    icon: 'icon-hybrid',
  },
};

export const SIGN_IN_RESULT_TYPE = {
  SUCCESS: 0,
  QR: 1,
  CODE_VERIFY: 2,
  DOCUMENT_VERIFICATION: 3,
};

export const DEFAULT_COUNTRY = 'ZZ';

export const PRODUCT_STATUS_ACTION = {
  DISABLED: 0,
  ENABLED: 1,
};

export const PRODUCT_STATUS_ACTION_LIST = {
  [PRODUCT_STATUS_ACTION.DISABLED]: {
    id: PRODUCT_STATUS_ACTION.DISABLED,
    label: 'disabled',
  },
  [PRODUCT_STATUS_ACTION.ENABLED]: {
    id: PRODUCT_STATUS_ACTION.ENABLED,
    label: 'enabled',
  },
};

export const COMMISSION_PLAN_TYPES = {
  cpa: 2,
  revenue: 3,
  subAffiliates: 4,
  hybrid: 5,
};

export const LAYOUT_ELEMENTS = {
  spe: 6,
  earn_money: 7,
  marketing_tools: 8,
  social: 9,
  about_us: 10,
  payment: 11,
  testimonials: 12,
  contact_us: 13,
  slogan: 14,
  faq: 15,
};

export const YES_NO_DROPDOWN = [
  {
    label: 'yes',
    id: '1',
  },
  {
    label: 'no',
    id: '0',
  },
];

export const COMPARE_LIMIT = 2;

export const VERIFICATION_IDS = {
  EMAIL: '1',
  DOCUMENT: '2',
  FACE_TO_FACE: '3',
  DEFAULT_STATUS: '4',
  SMS: '5',
};

export const VERIFICATION_USER_INFO_KEYS = {
  [VERIFICATION_IDS.EMAIL]: 'verified',
  [VERIFICATION_IDS.DOCUMENT]: 'verifiedDoc',
  [VERIFICATION_IDS.FACE_TO_FACE]: 'verifiedF2F',
};

export const VERIFICATION_LIST = {
  [VERIFICATION_IDS.EMAIL]: {
    label: 'email',
    toolTipText: 'a-way-to-verify-the-user-by-email-after-enabling-i',
  },
  [VERIFICATION_IDS.DOCUMENT]: {
    label: 'document',
    toolTipText: 'a-way-to-verify-the-user-by-document-after-enablin',
  },
  [VERIFICATION_IDS.FACE_TO_FACE]: {
    label: 'face-to-face',
    toolTipText: 'a-way-to-verify-the-user-face-to-face-after-enabli',
  },
  [VERIFICATION_IDS.DEFAULT_STATUS]: {
    label: 'passive-status',
    toolTipText: 'enable-to-make-all-new-registered-users-passive-by',
  },
  [VERIFICATION_IDS.SMS]: {
    label: 'phone-sms-code',
    toolTipText: '',
  },
};

export const API_BOOL = {
  YES: 'YES',
  NO: 'NO',
};

export const VERIFICATION_STATUS_ID = {
  YES: '1',
  NO: '0',
};

export const VERIFICATION_STATUS = {
  [VERIFICATION_STATUS_ID.YES]: {
    TITLE: 'verified',
    ICON: 'bc-icon-check',
  },
  [VERIFICATION_STATUS_ID.NO]: {
    TITLE: 'non-verified',
    ICON: 'bc-icon-clear',
  },
};

export const DEFAULT_COMMISSION_PLAN_TYPE = {
  REVENUE: 'revenue',
  CPA: 'cpa',
  HYBRID: 'hybrid',
};

export const PROJECT_STATUS = {
  TRIAL: 0,
  PRODUCTION: 1,
  INACTIVE: 2,
  LOST: 3,
  TERMINATED: 4,
};

export const PROJECT_STATUS_LIST = {
  [PROJECT_STATUS.TRIAL]: {
    LABEL: 'trial',
  },
  [PROJECT_STATUS.PRODUCTION]: {
    LABEL: 'production',
  },
  [PROJECT_STATUS.INACTIVE]: {
    LABEL: 'inactive',
  },
  [PROJECT_STATUS.LOST]: {
    LABEL: 'lost',
  },
  [PROJECT_STATUS.TERMINATED]: {
    LABEL: 'terminated',
  },
};

export const PAYMENT_METHODS_ACTION_TYPES = {
  SET_LIMITS: 1,
  SET_FTD_RESTRICTIONS: 2,
};

export const getPaymentMethodActions = t => [
  {
    label: t('set-limits'),
    value: PAYMENT_METHODS_ACTION_TYPES.SET_LIMITS,
  },
  {
    label: t('set-ftd-restrictions'),
    value: PAYMENT_METHODS_ACTION_TYPES.SET_FTD_RESTRICTIONS,
  },
];

export const MIN_AGE = 18;
export const MAX_AGE = 100;
export const MAX_FILE_SIZE_IN_MB = 2000000;
export const MAX_SIZE_MESSAGE = 3000;

export const REGISTRATION_CONFIRM_TYPE_EMAIL = {
  title: 'thank-you-for-your-registration',
  icon: emailVerifySuccess,
  text: 'an-email-has-been-sent-to-you',
};

export const REGISTRATION_CONFIRM_TYPE_DEFAULT = {
  title: 'thank-you-you-are-successfully-registered',
  icon: registrationSuccess,
};

export const PAYOUT_BY_FILE_VALUE = {
  INVALID_USER: '0',
  VALID_USER: '1',
  INVALID_AMOUNT: '2',
};

export const PAYOUT_BY_FILE = {
  [PAYOUT_BY_FILE_VALUE.INVALID_USER]: {
    title: 'invalid-user',
  },
  [PAYOUT_BY_FILE_VALUE.VALID_USER]: {
    title: 'valid',
  },
  [PAYOUT_BY_FILE_VALUE.INVALID_AMOUNT]: {
    title: 'invalid-amount',
  },
};

export const REPORT_TYPE = {
  DEPOSITING_PLAYERS_COUNT: 'ftd-count-depositing-players-count',
  //BETTING_PLAYERS_COUNT: 'ftd-count-betting-players-count',
  PLAYERS_NGR_AMOUNT: 'ftd-players-ngr-players-ngr',
  PLAYERS_DEPOSIT_AMOUNT: 'ftd-players-deposit-amount-players-deposit-amount',
  PLAYERS_BET_AMOUNT: 'ftd-players-bet-amount-players-bet-amount',
};

export const REPORT_TYPE_COLUMNS = {
  [REPORT_TYPE.DEPOSITING_PLAYERS_COUNT]: 'depositing-players',
  //[REPORT_TYPE.BETTING_PLAYERS_COUNT]: 'betting-players',
  [REPORT_TYPE.PLAYERS_NGR_AMOUNT]: 'players-ngr-amount',
  [REPORT_TYPE.PLAYERS_DEPOSIT_AMOUNT]: 'players-deposit-amount',
  [REPORT_TYPE.PLAYERS_BET_AMOUNT]: 'players-bet-amount',
};

export const MASKS = {
  CPF: 'XXX.XXX.XXX-XX',
};

export const DOMAIN_NAMES_INPUT = {
  AFFILIATE_DOMAIN: {
    COLOR: '#fdc625',
    TITLE: 'affiliates-website-url',
  },
  PARTNER_DOMAIN: {
    COLOR: '#7dc681',
    TITLE: 'partner-main-website-url',
  },
  PLAYERS_REFERAL: {
    COLOR: '#7dc681',
    TITLE: 'players-referral-website-url',
  },
};

export const DOMAIN_NAMES_TABS = {
  main: 'main',
  additional: 'additional',
};
